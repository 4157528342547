import { indexInfos } from "chinese-dos-games-data-export/lib/index-page-infos";

for (let gameInfo of indexInfos.popular_games) {
  if ("coverImage" in gameInfo) {
    gameInfo.coverFilename = gameInfo.coverImage.filename;
    gameInfo.width = gameInfo.coverImage.width;
    gameInfo.height = gameInfo.coverImage.height;
  }
}

export const popular_games = indexInfos.popular_games;
export const game_count = indexInfos.game_count;