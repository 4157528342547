<template>
  <b-card class="mb-3" no-body>
    <GameCardImageLink :identifier="identifier">
      <slot v-if="cover_url"> </slot>
      <GameImagePlaceholder v-else />
    </GameCardImageLink>
    <GameCardBody :game_info="game_info" />
  </b-card>
</template>

<script>
import game_info_mixin from "@/mixins/game_info_mixin";
import GameCardBody from "./GameCardBody";
import GameImagePlaceholder from "./GameImagePlaceholder";
import GameCardImageLink from "./GameCardImageLink";

export default {
  name: "GameCard",
  props: ["game_info"],
  mixins: [game_info_mixin],
  components: {
    GameCardBody,
    GameImagePlaceholder,
    GameCardImageLink,
  },
};
</script>
