import { jsdos_path } from "@/utils/js-dos";
import getSave from "./getSave";
import { logger } from "./log"

export default async (dom, binary_url, save_key, config) => {
  // await import(
  //   /* webpackChunkName: "js-dos-wrapper" */ /* webpackPrefetch: true */ "@/vendors/js-dos/dist/js-dos.js"
  // );

  await import(
    /* webpackChunkName: "js-dos-wrapper" */ /* webpackPrefetch: true */ "emulators/dist/emulators.js"
  );
  await import(
    /* webpackChunkName: "js-dos-wrapper" */ /* webpackPrefetch: true */ "emulators-ui/dist/emulators-ui.js"
  );

  const emulatorsUi = window.emulatorsUi;
  const Dos = emulatorsUi.dos;

  logger.log(`Binary Url: ${binary_url}`);
  const run_args = [binary_url];
  // const config = {
  //   donate: false,
  //   noSideBar: true,
  // };
  window.emulators.pathPrefix = jsdos_path;

  const save = await getSave(save_key);

  if (save) {
    logger.log(`Load Game Save Successfully`);
    run_args.push(URL.createObjectURL(new Blob([save])));
  }

  const player = Dos(dom);

  // Remove top-right option controls
  player.layers.options.optionControls = [];

  if (config?.notyf) {
    player.layers.notyf = config.notyf;
  }

  // if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
  //   player.autolock = false;
  // }

  logger.log(`Starting js-dos Instance`);
  const ci = await player.run(...run_args);

  return {
    ci,
    player,
    Dos,
    emulatorsUi,
  };
};
