<template>
  <b-card class="mb-3" title="更多游戏" title-tag="h5">
    <b-card-sub-title sub-title-tag="h6" class="mb-2 text-muted">
      目前共有 {{ game_count }} 款游戏
    </b-card-sub-title>
    <router-link :to="{ name: 'games' }" class="card-link">
      游戏列表
    </router-link>
  </b-card>
</template>

<script>
export default {
  name: "GameCountCard",
  props: ["game_count"],
};
</script>