<template>
  <b-card-img-lazy
    :top="true"
    :src="cover_url"
    :alt="cover_alt"
    :srcset="cover_srcset_array"
    sizes="calc(100vw -32px)"
    blank-color="#bbb"
    :height="(cover_height / cover_width) * (html_width - 32)"
    v-if="cover_url"
  />
</template>

<script>
import game_info_mixin from "@/mixins/game_info_mixin";
import widescreen_mixin from "@/mixins/widescreen_mixin";

export default {
  name: "ListLazyImageCard",
  props: ["game_info"],
  mixins: [game_info_mixin, widescreen_mixin],
};
</script>
