import { jsdos_save_db, jsdos_save_store_name } from "./constants";
import openSaveDB from "./openSaveDB";
import { saveLogger } from "./log";

export default async function getSave(save_key) {
  try {
    saveLogger.log(
      `Loading from ${jsdos_save_db}/${jsdos_save_store_name}/${save_key}`
    );

    const db = await openSaveDB();
    const save = await db.get(jsdos_save_store_name, save_key);
    db.close();

    return save;
  } catch (e) {
    saveLogger.error(`Failed to load save: ${e}`);
    return undefined;
  }
}
