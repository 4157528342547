import { jsdos_save_db, jsdos_save_store_name } from "./constants";
import openSaveDB from "./openSaveDB";
import { saveLogger } from "./log";

export default async function putSave(save, save_key) {
  saveLogger.log(
    `Saving to ${jsdos_save_db}/${jsdos_save_store_name}/${save_key}`
  );

  const db = await openSaveDB();
  await db.put(jsdos_save_store_name, save, save_key);
  db.close();

  saveLogger.log(`Successfully saved`);
}
