<template>
  <div class="cover-placeholder">暂无图片</div>
</template>

<script>
export default {
  name: "GameImagePlaceholder",
};
</script>

<style scoped>
.cover-placeholder {
  width: 100%;
  height: 150px;
  background-color: #bbb;
  line-height: 150px;
  text-align: center;
  font-weight: bold;
  font-size: 30px;
  color: #555;
  user-select: none;
}
</style>
