export default {
    data: function () {
        return {
            window_width: window.innerWidth,
            html_width: document.documentElement.clientWidth,
        };
    },
    mounted() {
        window.addEventListener("resize", this.onResize);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.onResize);
    },
    methods: {
        onResize() {
            if (this.window_width != window.innerWidth) {
                this.window_width = window.innerWidth;
            }
            if (this.html_width != document.documentElement.clientWidth) {
                this.html_width = document.documentElement.clientWidth;
            }
        },
    },
    computed: {
        is_widescreen: function () {
            return this.window_width > 550 ? true : false;
        },
    }
}
