<template>
  <router-link
    :to="{ name: 'game', params: { identifier: identifier } }"
    class="cover-link"
  >
    <slot></slot>
  </router-link>
</template>

<script>
export default {
  name: "GameCardImageLink",
  props: ["identifier"],
};
</script>

<style scoped>
.cover-link:hover {
  text-decoration: none;
}
</style>
