<template>
  <div>
    <GamesLinkList v-if="game_infos.length >= 100" :game_infos="game_infos" />

    <div v-else>
      <GameGrid
        v-if="is_widescreen"
        :game_infos="game_infos"
        :show_count="show_count"
      />
      <GameList v-else :game_infos="game_infos" :show_count="show_count" />
    </div>
  </div>
</template>

<script>
import widescreen_mixin from "@/mixins/widescreen_mixin";
import GameList from "./GameList";
import GameGrid from "./GameGrid";
import GamesLinkList from "@/components/GamesLinkList";

export default {
  name: "GamesView",
  mixins: [widescreen_mixin],
  props: ["game_infos", "show_count"],
  components: {
    GameList,
    GameGrid,
    GamesLinkList,
  },
};
</script>
