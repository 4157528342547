// Saves Database settings
export const jsdos_save_db = "js-dos-saves";
export const jsdos_save_store_name = "saves";

// Script loading settings
// import { publicPath } from "../constants";

const jsdos_root = `static/js-dos-emulators/`;
// no Need since webpack import is used
// export const jsdos_script_url = `${publicPath}${jsdos_root}js-dos.js`;

// Follow same-origin policy
export const jsdos_path = `/${jsdos_root}`;

// Force js-dos games settings
export const jsdos_games = ["金瓶梅", "红色警戒", "欢乐幸福人", "吃豆人"];

export const worker_script = `${jsdos_path}wdosbox.js`;
export const worker_wasm = `${jsdos_path}wdosbox.wasm`;