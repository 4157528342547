<template>
  <b-card-img-lazy
    :top="true"
    :src="cover_url"
    :alt="cover_alt"
    :srcset="cover_srcset_array"
    sizes="248px"
    blank-color="#bbb"
    :height="(cover_height / cover_width) * 248"
    width="248"
    v-if="cover_url"
  />
</template>

<script>
import game_info_mixin from "@/mixins/game_info_mixin";

export default {
  name: "GridLazyImageCard",
  props: ["game_info"],
  mixins: [game_info_mixin],
};
</script>
