import { openDB } from "idb";
import { jsdos_save_db, jsdos_save_store_name } from "./constants";

export default async function openSaveDB() {
  const db = await openDB(jsdos_save_db, 1, {
    upgrade(db) {
      db.createObjectStore(jsdos_save_store_name);
    },
  });

  return db
}
