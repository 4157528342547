<template>
  <b-container class="mt-3">
    <GameCard
      v-for="(game_info, idx) in game_infos"
      :key="idx"
      :game_info="game_info"
      class="grid-item"
    >
      <LazyImageCard :game_info="game_info"></LazyImageCard>
    </GameCard>

    <GameCountCard
      v-if="show_count"
      :game_count="game_count"
      class="grid-item"
    />
  </b-container>
</template>

<script>
import { game_count } from "@/utils/info/index_info";

import { GameCountCard, GameCard } from "../GamesViewCommon";
import LazyImageCard from "./components/LazyImageCard";

export default {
  components: { GameCard, GameCountCard, LazyImageCard },
  name: "GameList",
  props: ["game_infos", "show_count"],
  computed: {
    game_count: function () {
      return game_count;
    },
  },
};
</script>

<style scoped>
.grid-item {
  width: 100%;
}
</style>
