<template>
  <b-card-body>
    <b-card-title title-tag="h5">
      <router-link
        :to="{ name: 'game', params: { identifier: identifier } }"
        class="title-link"
      >
        {{ name }}
      </router-link>
    </b-card-title>
  </b-card-body>
</template>

<script>
import game_info_mixin from "@/mixins/game_info_mixin";

export default {
  name: "GameCardBody",
  props: ["game_info"],
  mixins: [game_info_mixin],
};
</script>

<style scoped>
.title-link {
  color: inherit;
}
</style>
