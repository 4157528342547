import filesize from "filesize";
import { getDefaultHost } from "../utils/binary-host";
import { jsdos_games } from "@/utils/js-dos";
import { cover_image_prefix } from "@/utils/image";
import isChina from "@/utils/isChina";

export default {
  computed: {
    name: function () {
      return this.game_info.name["zh-Hans"];
    },
    zh_Hant_name() {
      return this.game_info.name["zh-Hant"];
    },
    en_name() {
      return this.game_info.name["en"];
    },
    identifier: function () {
      return this.game_info.identifier;
    },
    cover_width: function () {
      return this.game_info.width;
    },
    cover_height: function () {
      return this.game_info.height;
    },
    cover_url: function () {
      return this.game_info.coverFilename
        ? `${cover_image_prefix}${this.game_info.coverFilename}`
        : null;
    },
    cover_srcset: function () {
      if (isChina) {
        return {
          origin: {
            url: this.cover_url,
            width: this.cover_width,
            height: this.cover_height,
          },
          // w248: {
          //   url: `${this.cover_url}/w_248px.jpg`,
          //   width: 248,
          //   height: (this.cover_height * 248) / this.cover_width,
          // },
          // w372: {
          //   url: `${this.cover_url}/w_372px.jpg`,
          //   width: 372,
          //   height: (this.cover_height * 372) / this.cover_width,
          // },
          // w496: {
          //   url: `${this.cover_url}/w_496px.jpg`,
          //   width: 496,
          //   height: (this.cover_height * 496) / this.cover_width,
          // },
          // w744: {
          //   url: `${this.cover_url}/w_744px.jpg`,
          //   width: 744,
          //   height: (this.cover_height * 744) / this.cover_width,
          // },
        };
      } else {
        return {
          origin: {
            url: this.cover_url,
            width: this.cover_width,
            height: this.cover_height,
          },
        };
      }
    },
    cover_srcset_array: function () {
      return Object.values(this.cover_srcset)
        .filter((item) => item.width <= this.cover_width)
        .map((item) => `${item.url} ${item.width}w`);
    },
    cover_alt() {
      return `${this.name}的封面`;
    },
    binary_url: function () {
      const best_host = getDefaultHost();
      return `${best_host}/${encodeURIComponent(this.identifier)}.zip`;
    },
    jsdos_binary_url: function () {
      const best_host = getDefaultHost();
      return `${best_host}/${encodeURIComponent(this.identifier)}.jsdos.zip`;
    },
    use_jsdos: function () {
      return jsdos_games.includes(this.identifier);
    },
    drive_type: function () {
      if ("cdrom" in this.game_info) {
        return "cdrom";
      } else if ("floppy" in this.game_info) {
        return "floppy";
      } else {
        return "hdd";
      }
    },
    release_year: function () {
      return this.game_info.releaseYear;
    },
    structured_data: function () {
      const structured_data_dict = {
        "@context": "http://schema.org",
        "@type": "Game",
        name: this.name,
        url: this.game_web_url,
      };

      if (this.release_year) {
        structured_data_dict["copyrightYear"] = this.release_year;
      }

      if (this.cover_url) {
        structured_data_dict["image"] = this.cover_url;
      }

      return structured_data_dict;
    },
    filesize: function () {
      return this.game_info.filesize;
    },
    formatted_filesize: function () {
      const formatted_filesize = filesize(this.filesize, { round: 0 });
      const filesize_in_bytes = this.filesize
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return `${formatted_filesize} (${filesize_in_bytes} bytes)`;
    },
    executable() {
      return this.game_info.executable ?? "";
    },
    img() {
      return this.game_info.img;
    },
    download_web_url() {
      return `https://dos.zczc.cz/games/${this.identifier}/download`;
    },
    game_web_url() {
      return `https://dos.zczc.cz/games/${this.identifier}/`;
    },
    info_web_url() {
      return `https://dos.zczc.cz/games/${this.identifier}/info`;
    },
  },
};
