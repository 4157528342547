<template>
  <b-container
    class="mt-3 grid"
    v-masonry="containerId"
    transition-duration="0.3s"
    item-selector=".grid-item"
  >
    <GameCard
      v-for="(game_info, idx) in game_infos"
      :key="idx"
      :game_info="game_info"
      class="grid-item mr-3"
    >
      <LazyImageCard :game_info="game_info"></LazyImageCard>
    </GameCard>

    <GameCountCard
      v-if="show_count"
      :game_count="game_count"
      class="grid-item mr-3"
    />
  </b-container>
</template>

<script>
import { game_count } from "@/utils/info/index_info";

import { GameCountCard, GameCard } from "../GamesViewCommon";
import LazyImageCard from "./components/LazyImageCard";

export default {
  name: "GameGrid",
  props: ["game_infos", "show_count"],
  data: function () {
    return {
      containerId: null,
    };
  },
  computed: {
    game_count() {
      return game_count;
    },
  },
  components: { GameCard, GameCountCard, LazyImageCard },
};
</script>

<style scoped>
.grid-item {
  width: 250px;
}
</style>