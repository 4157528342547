import openSaveDB from "./openSaveDB";

import { jsdos_save_store_name } from "./constants";

export default async function () {
  const db = await openSaveDB();
  const keys = await db.getAllKeys(jsdos_save_store_name);
  const saves = await db.getAll(jsdos_save_store_name);
  const result = keys.reduce((acc, k, i) => ((acc[k] = saves[i]), acc), {});
  db.close();
  return result;
}
