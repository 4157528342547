import putSave from "./putSave";

export default async (ci, save_key) => {
  const save = (await ci.persist()).buffer;
  if (save) {
    await putSave(save, save_key);
    return {
      save_length: save.byteLength,
      last_save_time: Date.now(),
    };
  }
};
